<script setup lang="ts"></script>

<template>
  <div>
    <slot />
  </div>
</template>

<style>
html,
body,
#__nuxt,
#__layout {
  background-color: transparent !important;
}

body {
  overflow: hidden;
}
</style>
